<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h5 class="mb-4">
          <strong>Tambah Voucher Baru</strong>
        </h5>
        <a-form :form="form"  @submit="handleSubmit">
          <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Jenis Test Laboratorium">
            <a-select
              :disabled="submitting"
              v-decorator="[
                'testLabTypeId',
                { rules: [{ required: true, message: 'Jenis Test Laboratorium harus diisi!' }] },
              ]"
              placeholder="Jenis Test Laboratorium"
            >
              <a-select-option v-for="testlabType in testlabTypeList" :key="testlabType.id" :value="testlabType.id">
                  {{testlabType.name}}
               </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Jumlah Voucher">
            <a-input
              :disabled="submitting"
              placeholder="Masukkan Jumlah Voucher"
              v-decorator="['count', {rules: [{ required: true, message: 'Jumlah Voucher harus diisi!' }]}]"
            />
          </a-form-item>
          <button type="submit" class="btn btn-success px-5" :disabled="submitting">Simpan Data</button>
        </a-form>
      </div>
    </div>
  </div>
</template>
<script>

import router from '@/router'
import { create } from '@/services/axios/api/voucher'
import { getAll } from '@/services/axios/api/testlabtype'
import { notification } from 'ant-design-vue'

const labelCol = {
  xs: { span: 24 },
  sm: { span: 4 },
}
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 12 },
}
export default {
  data: function () {
    return {
      form: this.$form.createForm(this),
      labelCol,
      wrapperCol,
      testlabTypeList: [],
      submitting: false,
    }
  },
  created() {
    this.getTestLabTypeList()
  },
  methods: {
    async getTestLabTypeList() {
      const res = await getAll()
      this.testlabTypeList = res
    },
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.submitting = true
          console.log('Received values of form: ', values)
          try {
            const res = await create(values)
            if (res && res.data) {
              notification.success({
                message: 'Sukses',
                description: 'Data berhasil ditambahkan',
                duration: 5,
              })
              router.push('/voucher')
            }
            this.submitting = false
          } catch (e) {
            this.submitting = false
          }
        }
      })
    },
  },
}
</script>
