import apiClient from '@/services/axios'

const getAll = async () => {
  const res = await apiClient.get('/voucher')
  if (res) {
    return res.data
  } else {
    return []
  }
}

const getById = async (id) => {
  const res = await apiClient.get(`/voucher/${id}`)
  if (res) {
    return res.data
  } else {
    return null
  }
}

const deleteById = async (id) => {
  const res = await apiClient.delete(`/voucher/${id}`)
  if (res) {
    return res.data
  } else {
    return null
  }
}

const create = async (data) => {
  const res = await apiClient.post('/voucher', data, {})
  if (res) {
    return res
  } else {
    return null
  }
}

const editById = async (id, data) => {
  const res = await apiClient.patch(`/voucher/${id}`, data, {})
  if (res) {
    return res
  } else {
    return null
  }
}

export {
  getAll,
  getById,
  deleteById,
  create,
  editById,
}
